import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Catering from "../../images/catering.jpg"
import Celebration from "../../images/celebration.jpg"
import Grill from "../../images/grill.jpg"

const CateringPage = () => (
  <Layout>
    <SEO title="Catering • Events" />

    <div
      className="site-blocks-cover inner-page overlay catering-cover"
      style={{ backgroundImage: `url(${Catering})` }}
    >
      <div className="row align-items-center justify-content-center mx-2">
        <div className="col-12 text-center">
          <h1 className="text-uppercase text-center">
            <span>Streetfood Catering</span>
            <br />
            <span>für Ihre Veranstaltung</span>
          </h1>
          <span className="caption bg-primary p-2 text-white text-center">
            für eine unvergessliche Feier
          </span>
        </div>
      </div>
    </div>

    <div className="yellow-lines"></div>

    <div className="site-half mt-15">
      <div className="img-bg-1" style={{ backgroundImage: `url(${Grill})` }}></div>
      <div className="container">
        <div className="row no-gutters align-items-stretch">
          <div className="col-lg-5 ml-lg-auto py-5 py-5 px-5 px-md-0">
            <span className="caption d-block mb-2 font-secondary font-weight-bold">
              Ihre Veranstaltung
            </span>
            <h2 className="site-section-heading text-uppercase font-secondary mb-5">
              Unser Service
            </h2>
            <p>
              Ob Privatfeiern, wie Geburtstage, Hochzeiten, Sommerfeste oder die
              professionelle Durchführung von Firmenfeiern jeglicher Art: Wir
              kommen mit unserem originellen Food Truck und machen Ihr Event
              außergewöhnlich.
            </p>
            <p>
              Und sollte mal kein entsprechender Stellplatz verfügbar sein
              kommen wir gerne mit unserem mobilen Equipment vorbei.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="site-half block mb-5">
      <div
        className="img-bg-1 right"
        style={{ backgroundImage: `url(${Celebration})` }}
      ></div>
      <div className="container">
        <div className="row no-gutters align-items-stretch">
          <div className="col-lg-5 mr-lg-auto py-5 px-5 px-md-0">
            <span className="caption d-block mb-2 font-secondary font-weight-bold">
              Ein Catering nach Ihren Vorstellungen
            </span>
            <h2 className="site-section-heading text-uppercase font-secondary mb-5">
              individuelle Menüs
            </h2>
            <p>
              Es muss nicht immer Burger sein. Gerne stellen wir Ihnen passend
              zu Ihrer Veranstaltung ein individuelles Menü zusammen.
            </p>
            <p>
              Wraps, Flammkuchen, Falafel, leichte Salate, Gegrilltes oder
              Traditionelles wie Schnitzel oder Spätzle - Helmart's hat für
              alle Kunden das passende Menü.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="site-section site-section-sm first-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12 text-center">
            <span className="caption d-block mb-2 font-secondary font-weight-bold">
              Einfach und unkompliziert
            </span>
            <h2 className="site-section-heading text-uppercase text-center font-secondary px-5 px-md-2">
              Und so funktionierts
            </h2>
          </div>
        </div>
        <div className="row row-howto">
          <div className="col-12 col-lg-4 mb-4 mb-lg-0 px-5 px-md-2">
            <h3 className="d-flex align-items-center">
              <span className="circle-icon-wrap mr-3">1.</span> Event-Menü
              besprechen
            </h3>
            <p>
              Passend zu Ihrer Veranstaltung und Ihren Gästen, stellen wir gemeinsam mit Ihnen
              ein individuelles Menü zusammen.
            </p>
          </div>
          <div className="col-12 col-lg-4 mb-4 mb-lg-0 px-5 px-md-2">
            <h3 className="d-flex align-items-center">
              <span className="circle-icon-wrap mr-3">2.</span> Vorbereitungen für
              Ihr Event
            </h3>
            <p>
              Helmart's bereitet Ihr gewünschtes Menü am
              selben Tag frisch zu und organisiert den weiteren Ablauf.
            </p>
          </div>
          <div className="col-12 col-lg-4 mb-4 mb-lg-0 px-5 px-md-2">
            <h3 className="d-flex align-items-center">
              <span className="circle-icon-wrap mr-3">3.</span> Helmart's Catering
              genießen
            </h3>
            <p>
              Vom Aufbau bis Abbau inkl. Müllentsorgung - Helmart's kümmert sich
              darum, damit Sie und Ihre Gäste das Event genießen können.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="py-5 bg-primary contact-box">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-8 text-center mb-3 mb-md-0">
            <h2 className="text-uppercase text-white mb-4" data-aos="fade-up">
              Haben Sie Interresse an Helmart's Catering?
            </h2>
            <div className="text-center text-uppercase mb-4">
              <span className="bg-white p-2 text-primary caption">
                Wir stellen Ihnen gerne ein individuelles
              </span>
              <br />
              <span className="bg-white p-2 text-primary caption">
                Angebot passend für Ihre Feier zusammen.
              </span>
            </div>
            <Link className="btn btn-ghost" to="/kontakt/">
              Anfrage senden
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default CateringPage
